import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

// Connects to data-controller="search"
export default class extends Controller {
  static values = { url: String, field: String }

  static targets = [ "searchField", "releasedTTFilter", "statusFilter" ]

  search(event) {
    const query = event.target.value.trim();
    this.sendTurboStreamRequest(query);
  }

  async sendTurboStreamRequest(query) {
    var urlParams = "q%5B" + encodeURIComponent(this.fieldValue) + "%5D=" + encodeURIComponent(query);
    const url = `${this.urlValue}&${urlParams}`;
    await get(url, {
      responseKind: 'turbo-stream'
    });
  }

  async applyCitationFilters(event) {
    if (event.target === this.searchFieldTarget && (event.target.value.length !== 0 && event.target.value.length < 3)) return;

    const filterParams = [
      {
        target: 'searchField',
        getValue: (el) => el.value.trim(),
      },
      {
        target: 'releasedTTFilter',
        getValue: (el) => el.checked,
      },
      {
        target: 'statusFilter',
        getValue: (el) => el.value,
      }
    ];

    const urlParams = filterParams
      .map(filter => {
        const element = this[`${filter.target}Target`];
        if (!element) return null;
        
        const value = filter.getValue(element);
        if (value === null) return null;
        
        const searchFilter = element.dataset['searchFilter'];
        return `q%5B${encodeURIComponent(searchFilter)}%5D=${encodeURIComponent(value)}`;
      })
      .filter(param => param !== null)
      .join('&');

    if (urlParams) {
      const url = `${this.urlValue}&${urlParams}`;

      // adds the filter to the URL without reloading the page
      if (event.target === this.releasedTTFilterTarget) {
        const currentUrlHasFilters = window.location.search.includes('q%5B');
        const action = currentUrlHasFilters ? 'replace' : 'advance';
        await Turbo.visit(url, { action });
        return
      }
      else {
        await get(url, { responseKind: 'turbo-stream' });
      }
    }

  }
}
