import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="company"
export default class extends Controller {
  static targets = [ 
    "referralPartnerFields", 
    "referralPartnerSelect", 
    "referralPartnerFee",
    "windowDecalUpload",
    "sleeveUpload"
  ]

  showreferralPartnerFields() {
    this.referralPartnerFieldsTarget.classList.remove("d-none")
    this.referralPartnerSelectTarget.disabled = false;
    this.referralPartnerFeeTarget.disabled = false;

  }
  hidereferralPartnerFields() {
    this.referralPartnerFieldsTarget.classList.add("d-none")
    this.referralPartnerSelectTarget.disabled = true;
    this.referralPartnerFeeTarget.disabled = true;
  }

  toggleWindowDecal(event) {
    this.windowDecalUploadTarget.style.display = 
      event.target.value.includes('custom') ? 'block' : 'none'
  }

  toggleSleeve(event) {
    this.sleeveUploadTarget.style.display = 
      event.target.value.includes('custom') ? 'block' : 'none'
  }
}
